<template>
  <div>
    <nav class="navigation">
      <ul>
        <li><a href="//v1.keep3r.network">Keep3r V1</a></li>
        <li><router-link to="/">Keep3r V2</router-link></li>
        <li><a href="//docs.keep3r.network" target="_blank">Docs</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
};
</script>
