<template>
  <div class="k-empty-list">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EmptyList',
};
</script>
